<template>
  <div class="pb-2">
    <h4 class="text-left font-weight-bolder font-medium-5">
      <!--      {{ $t('Taxes') }}-->
    </h4>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      trash-list-path="settings-taxes-trash-list"
      :is-searchable="false"
      :can-create="ACCESS_ABILITY_TAXES"
      :can-show-trash-list="ACCESS_ABILITY_TAXES"
      :create-page-url="{ name: 'settings-taxes-create' }"
      :table-config-options="getTableConfigOptions()"
    >
      <template #cell(value)="{ data }">
        {{ data.item.value + '%' }}
      </template>

      <template #cell(is_active)="{ data }">
        {{ data.item.is_active ? 'Active' : 'Inactive' }}
      </template>

      <template #cell(actions)="{ data }">
        <div class="text-nowrap d-flex justify-content-end">
          <feather-icon
            v-if="!data.item.is_default"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Set Inactive')"
            icon="LTrashIconKits"
            size="32"
            class="cursor-pointer mr-1"
            @click="setInActive(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Duplicate')"
            icon="LCopyIconKits"
            class="cursor-pointer mr-1"
            size="32"
            @click="dublicateTax(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('View')"
            icon="LStockEyeIcon"
            class="lightIcon cursor-pointer"
            size="32"
            @click="gotoNewPage({ name: 'settings-taxes-update', params: { id: data.item.id } }, $event)"
          />
        </div>
      </template>
      <template #cell(default)="{ data }">
        <div
          :id="data"
          class="d-flex align-items-center justify-content-center w-50"
          style="width: max-content; height: 20px;"
          :style="{pointerEvents: isLoadingOfSetDisabled ? 'none':'all'}"
          @click="setDefault(data)"
        >
          <div class="cursor-pointer">
            <b-form-radio
              :id="data.item.id.toString()"
              :checked="data.item.is_default"
              style="pointer-events: none"
              :value="true"
              :disabled="isLoadingOfSetDisabled"
            />
            <b-spinner
              v-if="isSettling(data.item.id)"
              style="margin-bottom: 4px;"
              class="ml-1"
              small
              label="Small Spinner"
              type="grow"
              variant="success"
            />
          </div>
        </div>
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import { BFormRadio, BSpinner, VBTooltip } from 'bootstrap-vue'
import LDraggableTable from '@/views/components/LDraggableTable.vue'
import tableConfig from '../taxesConfig'

export default {
  name: 'TaxesList',
  components: {
    LDraggableTable,
    BFormRadio,
    BSpinner,
    LTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
      isLoading: false,
    }
  },

  methods: {
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    setDefault(data) {
      const {
        item: { id },
      } = data

      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id

      this.sendNotification(
        this,
        {
          id,
        },
        `${this.MODULE_NAME}/setDefault`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
    },

    dublicateTax(data) {
      this.sendNotification(this, data, `${this.MODULE_NAME}/duplicate`)
        .then(() => {
          this.clear()
        })
        .catch(err => {
          this.error = err.response?.data
        }).finally(() => {
          this.isLoading = false
          this.refetchData()
        })
    },

    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    setInActive(data) {
      data.is_active = 'false'
      this.confirmNotification(this, data, `${this.MODULE_NAME}/update`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.refetchData()
        }).catch(err => {
          this.error = err.response?.data
        })
    },
    update(data, e) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name: 'settings-taxes-update',
          params: { id: data.id },
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name: 'settings-taxes-update',
          params: { id: data.id },
        })
      }
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getActiveList`,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'settings-taxes'
    const { tableColumns, fields, ACCESS_ABILITY_TAXES } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
      fields,
      ACCESS_ABILITY_TAXES,
    }
  },
}
</script>
