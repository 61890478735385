var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('h4',{staticClass:"text-left font-weight-bolder font-medium-5"}),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"trash-list-path":"settings-taxes-trash-list","is-searchable":false,"can-create":_vm.ACCESS_ABILITY_TAXES,"can-show-trash-list":_vm.ACCESS_ABILITY_TAXES,"create-page-url":{ name: 'settings-taxes-create' },"table-config-options":_vm.getTableConfigOptions()},scopedSlots:_vm._u([{key:"cell(value)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.value + '%')+" ")]}},{key:"cell(is_active)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.is_active ? 'Active' : 'Inactive')+" ")]}},{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"text-nowrap d-flex justify-content-end"},[(!data.item.is_default)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"cursor-pointer mr-1",attrs:{"title":_vm.$t('Set Inactive'),"icon":"LTrashIconKits","size":"32"},on:{"click":function($event){return _vm.setInActive(data.item)}}}):_vm._e(),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"cursor-pointer mr-1",attrs:{"title":_vm.$t('Duplicate'),"icon":"LCopyIconKits","size":"32"},on:{"click":function($event){return _vm.dublicateTax(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"lightIcon cursor-pointer",attrs:{"title":_vm.$t('View'),"icon":"LStockEyeIcon","size":"32"},on:{"click":function($event){return _vm.gotoNewPage({ name: 'settings-taxes-update', params: { id: data.item.id } }, $event)}}})],1)]}},{key:"cell(default)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center w-50",staticStyle:{"width":"max-content","height":"20px"},style:({pointerEvents: _vm.isLoadingOfSetDisabled ? 'none':'all'}),attrs:{"id":data},on:{"click":function($event){return _vm.setDefault(data)}}},[_c('div',{staticClass:"cursor-pointer"},[_c('b-form-radio',{staticStyle:{"pointer-events":"none"},attrs:{"id":data.item.id.toString(),"checked":data.item.is_default,"value":true,"disabled":_vm.isLoadingOfSetDisabled}}),(_vm.isSettling(data.item.id))?_c('b-spinner',{staticClass:"ml-1",staticStyle:{"margin-bottom":"4px"},attrs:{"small":"","label":"Small Spinner","type":"grow","variant":"success"}}):_vm._e()],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }